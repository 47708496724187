import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f27f45fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-container toggleswitch background-white border-radius-20" }
const _hoisted_2 = { class: "top-nav-bar-container" }
const _hoisted_3 = { class: "back-btn description" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "display-flex group-selection-header" }
const _hoisted_7 = { class: "font-size-20 no-margin" }
const _hoisted_8 = { class: "border-radius-20" }
const _hoisted_9 = { class: "toolbar-container" }
const _hoisted_10 = { class: "right-side-tool-bar create-new-btn-div" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "inner-flex-container" }
const _hoisted_13 = { class: "left-side-container" }
const _hoisted_14 = {
  key: 0,
  class: "edit-panel no-item right-side-container"
}
const _hoisted_15 = {
  key: 1,
  class: "edit-panel right-side-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_GroupSelectionListView = _resolveComponent("GroupSelectionListView")!
  const _component_GroupSelectionLandingPage = _resolveComponent("GroupSelectionLandingPage")!
  const _component_GroupSelectionEditItemPane = _resolveComponent("GroupSelectionEditItemPane")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_loading, {
      "show-loading": _ctx.showLoading,
      "border-color": _ctx.loaderBorderColor
    }, null, 8, ["show-loading", "border-color"]),
    (_ctx.modalPopupVisible)
      ? (_openBlock(), _createBlock(_component_ModalPopup, {
          key: 0,
          "show-modal-popup": _ctx.modalPopupVisible,
          "body-heading": _ctx.modalPopupContent,
          "show-cancel-button": _ctx.modalPopupShowCancelButton,
          "cancel-button-text": _ctx.modalPopupCancelButtonText,
          "confirm-button-text": _ctx.modalPopupConfirmButtonText,
          "show-close-button": _ctx.showCloseButton,
          onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
        }, null, 8, ["show-modal-popup", "body-heading", "show-cancel-button", "cancel-button-text", "confirm-button-text", "show-close-button"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: _ctx.GetRedirectionUrl(),
          class: "bold-text"
        }, [
          _createElementVNode("img", {
            src: _ctx.backIcon,
            class: "margin-right-10"
          }, null, 8, _hoisted_5),
          _createTextVNode(_toDisplayString(_ctx.$t('Button.Back')), 1)
        ], 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("Participants.GroupSelections")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", {
            id: "btnCreateNewGroupSelection",
            class: "btn btn-primary create-new-btn",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.CreateNewGroupSelectionItem()))
          }, [
            _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_11),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("CreateNew" )), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_GroupSelectionListView, {
            "group-selection-questions": _ctx.groupSelectionQuestions,
            "selected-group-item": _ctx.selectedItem[0],
            onChangeSelectedItem: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ChangeGroupSelectionSelectedItem($event))),
            onUpdateSortingGroupSelectionListing: _cache[3] || (_cache[3] = ($event: any) => (_ctx.UpdateSortingGroupSelectionListing($event))),
            onGroupSelectionCancelOperation: _cache[4] || (_cache[4] = ($event: any) => (_ctx.CloseGroupSelectionEditPane()))
          }, null, 8, ["group-selection-questions", "selected-group-item"])
        ]),
        (_ctx.showLandingPage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_component_GroupSelectionLandingPage, {
                onCreateNewItem: _cache[5] || (_cache[5] = ($event: any) => (_ctx.CreateNewGroupSelectionItem()))
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_component_GroupSelectionEditItemPane, {
                ref: "EditGroupSelectionItem",
                "selected-item": _ctx.selectedItem[0],
                groups: _ctx.groups,
                onGroupSelectionCancelOperation: _cache[6] || (_cache[6] = ($event: any) => (_ctx.CloseGroupSelectionEditPane())),
                onGroupSelectionDeleteOperation: _cache[7] || (_cache[7] = ($event: any) => (_ctx.DeleteGroupSelection()))
              }, null, 8, ["selected-item", "groups"])
            ]))
      ])
    ])
  ]))
}